<template>
  <div>
    <table class="results-table">
      <tr class="header">
        <td v-if="currentDealer"></td>
        <td>Offer</td>
        <td v-if="currentReseller">Private Price</td>
        <td>Wholesaler</td>
        <td>Offer Type</td>
        <td v-if="currentDealer">Adjusted From</td>
        <td>Offer Requested</td>
        <td>Offer Updated</td>
        <td v-if="currentDealer">Dealer</td>
        <td v-if="!small">Comments</td>
        <td v-if="!small && (currentDealer ? currentDealer.is_manager : true)">Options</td>
      </tr>
      <offer-table-row
        v-for="offer in sortedOffers(true)"
        v-bind:key="offer.url"
        :offer="offer"
        :winningOffer="form.winning_offer"
        :small="small"
        :allowArchive="!form.is_archived"
        :showDropdown="offerDropdownId == offer.url"
        @offerOptionClicked="emitSignal"
        @onTradedWithPurpose="emitTradedSignal"
        @onShowComments="showComments"
        @onDropdownClicked="onDropdownClicked"
      />
      <tr
        v-if="!small && currentDealership && currentDealership.is_tender_account"
        v-on:click="emitShouldAdjustReserve()"
      >
        <td colspan="10" class="body-2-bold reserve-td data-row selectable">
          Reserve: ${{ form.reserve_price ? form.reserve_price : 0 }}
        </td>
      </tr>
      <tr v-if="currentDealership && !currentDealership.is_tender_account && calculatedTradePrice()">
        <td colspan="10" class="body-2-bold reserve-td data-row">
          Calculated Trade Price: ${{ calculatedTradePrice() }}
        </td>
      </tr>
      <offer-table-row
        v-for="offer in sortedOffers(false)"
        v-bind:key="offer.url"
        :offer="offer"
        :small="small"
        :winningOffer="form.winning_offer"
        :allowArchive="!form.is_archived"
        :showDropdown="offerDropdownId == offer.url"
        @offerOptionClicked="emitSignal"
        @onTradedWithPurpose="emitTradedSignal"
        @onShowComments="showComments"
        @onDropdownClicked="onDropdownClicked"
      />
    </table>
    <base-modal-view
      v-if="offerToShowForComments"
      :title="
        offerToShowForComments.reseller_full_name
          ? offerToShowForComments.reseller_full_name
          : offerToShowForComments.reseller
          ? offerToShowForComments.reseller.first_name + ' ' + offerToShowForComments.reseller.last_name
          : offerToShowForComments.custom_name
      "
      :message="offerToShowForComments.comments"
      @cancel="offerToShowForComments = null"
    />
  </div>
</template>

<script>
import OfferTableRow from "./OfferTableRow.vue";
import { mapState } from "vuex";
import _ from "lodash";
import { getTopValue } from "../../../../helpers/v2/offerHelpers";
import BaseModalView from "../../../Modals/v2/BaseModalView.vue";

export default {
  name: "OfferTable",
  components: {
    OfferTableRow,
    BaseModalView
  },
  props: {
    form: {
      type: Object
    },
    small: {
      type: Boolean,
      default: false
    }
  },
  data: function () {
    return {
      offerToShowForComments: null,
      offerDropdownId: null
    };
  },
  methods: {
    sortedOffers: function (greaterThanReserve) {
      if (!this.form.offers) {
        return [];
      }
      var toReturn = _.cloneDeep(this.form.offers);
      if (this.currentDealership && this.currentDealership.is_tender_account) {
        return toReturn
          .sort((a, b) => this.sortOffer(a, b))
          .filter(offer =>
            this.form.reserve_price != null
              ? this.getTopValue(offer) >= this.form.reserve_price == greaterThanReserve
              : true
          );
      } else if (this.currentDealer) {
        return toReturn
          .sort((a, b) => this.sortOffer(a, b))
          .filter(offer =>
            this.form.reserve_price != null
              ? this.getTopValue(offer) >= this.calculatedTradePrice() == greaterThanReserve
              : true
          );
      } else {
        if (greaterThanReserve) {
          if (this.form.forwarded_offers) {
            this.form.forwarded_offers.forEach(offer => {
              toReturn.push(offer);
            });
          }
          return toReturn;
        } else {
          return [];
        }
      }
    },
    sortOffer: function (a, b) {
      var offerAValue = 0;
      var offerBValue = 0;

      if (a.adjusted_value) {
        offerAValue = a.adjusted_value;
      } else if (a.price) {
        offerAValue = a.price;
      }

      if (b.adjusted_value) {
        offerBValue = b.adjusted_value;
      } else if (b.price) {
        offerBValue = b.price;
      }

      return offerBValue - offerAValue;
    },
    getTopValue: function (offer) {
      return getTopValue(offer, this.currentDealer);
    },
    selectedOffer: function (offer) {
      this.$emit("selectedOffer", offer);
    },
    emitSignal: function (emitDict) {
      this.$emit(emitDict.signalName, emitDict.offer);
    },
    emitTradedSignal: function (emitDict) {
      this.$emit("onTradedWithPurpose", emitDict);
    },
    emitShouldAdjustReserve: function () {
      this.$emit("onShouldAdjustReserve");
    },
    onDropdownClicked: function (id) {
      if (this.offerDropdownId == id) {
        this.offerDropdownId = null;
      } else {
        this.offerDropdownId = id;
      }
    },
    calculatedTradePrice: function () {
      if (this.form.tpc_retail_price || this.form.tpc_yard_fee || this.form.tpc_margin || this.form.tpc_on_road_costs) {
        var damageCosts = 0;
        if (this.form.body_damages) {
          this.form.body_damages.forEach(damage => {
            damageCosts += damage.cost;
          });
        }
        return (
          this.form.tpc_retail_price -
          this.form.tpc_yard_fee -
          damageCosts -
          this.form.tpc_margin -
          this.form.tpc_on_road_costs
        );
      }
      return 0;
    },
    showComments: function (emitDict) {
      this.offerToShowForComments = emitDict.offer;
    }
  },
  computed: {
    ...mapState({
      currentDealer: state => state.dealershipStore.currentDealer,
      currentDealership: state => state.dealershipStore.currentDealership,
      currentReseller: state => state.resellerStore.currentReseller
    })
  }
};
</script>

<style lang="scss" scoped>
@import "@/scss/v2/_variables.scss";
@import "@/scss/v2/base.scss";

.reserve-td {
  text-align: center;
  background-color: $CARD_BACKGROUND_COLOR;
}
</style>
