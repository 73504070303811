<template>
  <tr
    :class="{
      'data-row': true,
      selectable: true,
      'data-row-bold': (winningOffer && offer.url == winningOffer.url) || offer.is_final
    }"
    v-on:click.stop="
      emitDropdownSignal(offer.url);
      showPurpose = false;
    "
  >
    <td v-if="currentDealer">
      <div class="flex-row" v-if="winningOffer && offer.url == winningOffer.url">
        <img src="/assets/img/icon-winner.svg" class="margin-right-025 small-icon" />
        Winner
      </div>
      <div class="flex-row" v-if="(!winningOffer || offer.url != winningOffer.url) && offer.is_final">
        <img src="/assets/img/icon-final.svg" class="margin-right-025 small-icon" />
        Final
      </div>
      <div class="flex-row" v-if="offer.passed">
        <img src="/assets/img/icon-passed.svg" class="margin-right-025 small-icon" />
        Passed
      </div>
    </td>
    <td>{{ getTopValue(offer) ? "$" + getTopValue(offer) : "—" }}</td>
    <td v-if="currentReseller">{{ offer.private_price ? "$" + offer.private_price : "—" }}</td>
    <td>
      {{
        offer.reseller_full_name
          ? offer.reseller_full_name
          : offer.reseller
          ? offer.reseller.first_name + " " + offer.reseller.last_name
          : offer.custom_name
      }}
    </td>
    <td>{{ offer.reseller ? "In-App Offer" : "Manual Offer" }}</td>
    <td v-if="currentDealer">
      {{ getSecondaryValue(offer) ? "$" + Number(getSecondaryValue(offer)).toLocaleString() : " — " }}
    </td>
    <td>
      {{
        offer.date_time_created
          ? new Date(offer.date_time_created)?.toLocaleDateString() +
            " " +
            new Date(offer.date_time_created)?.toLocaleTimeString()
          : " — "
      }}
    </td>
    <td>
      {{
        offer.date_time_updated
          ? new Date(offer.date_time_updated)?.toLocaleDateString() +
            " " +
            new Date(offer.date_time_updated)?.toLocaleTimeString()
          : " — "
      }}
    </td>
    <td v-if="currentDealer">
      {{
        offer.dealer && getDealer(offer.dealer)
          ? getDealer(offer.dealer).first_name + " " + getDealer(offer.dealer).last_name
          : " — "
      }}
    </td>
    <td v-if="!small">
      {{ offer.comments ? "Comments attached" : " — " }}
    </td>
    <td v-if="!small && (currentDealer ? currentDealer.is_manager : true)" class="position-relative">
      <img src="/assets/img/icon-more-dots.svg" class="small-icon pointer-cursor" />
      <div v-if="showDropdown" class="dropdown shadow body-2 padding-vertical-025 min-width-192">
        <div
          class="padding-vertical-05 padding-horizontal pointer-cursor highlight-on-hover flex-row"
          v-if="!offer.is_final && currentDealer"
          v-on:click.stop="emitSignal('onFinal')"
        >
          Mark as Final
        </div>
        <div
          class="padding-vertical-05 padding-horizontal pointer-cursor highlight-on-hover flex-row"
          v-if="!offer.is_final && currentDealer && allowArchive"
          v-on:click.stop="emitSignal('onFinalAndArchive')"
        >
          Mark as Final and Archive
        </div>
        <div
          class="padding-vertical-05 padding-horizontal pointer-cursor highlight-on-hover flex-row"
          v-if="offer.is_final && currentDealer"
          v-on:click.stop="emitSignal('onFinal')"
        >
          Unmark as Final
        </div>
        <div
          class="padding-vertical-05 padding-horizontal pointer-cursor highlight-on-hover flex-row"
          v-if="!(winningOffer && offer.url == winningOffer.url) && currentDealer"
          v-on:click.stop="
            showPurpose = true;
            emitDropdownSignal(null);
          "
        >
          Mark as Traded
        </div>
        <div
          class="padding-vertical-05 padding-horizontal pointer-cursor highlight-on-hover flex-row"
          v-if="winningOffer && offer.url == winningOffer.url && currentDealer"
          v-on:click.stop="emitSignal('onUntraded')"
        >
          Unmark as Traded
        </div>
        <div
          class="padding-vertical-05 padding-horizontal pointer-cursor highlight-on-hover flex-row"
          v-on:click.stop="emitSignal('onAdjust')"
        >
          Adjust Offer
        </div>
        <div
          class="padding-vertical-05 padding-horizontal pointer-cursor highlight-on-hover flex-row"
          v-if="currentDealer"
          v-on:click.stop="emitSignal('onHistory')"
        >
          View History
        </div>
        <div
          class="padding-vertical-05 padding-horizontal pointer-cursor highlight-on-hover flex-row"
          v-if="offer.comments"
          v-on:click.stop="emitCommentsSignal()"
        >
          View Comments
        </div>
      </div>
      <div
        v-if="showPurpose"
        v-on:click.stop=""
        class="traded-purpose-dropdown shadow padding-1-05 min-width-320 flex-column"
      >
        <div class="flex-row">
          <h4 class="h4 margin-right-auto vertical-flex-center">Traded Purpose</h4>
          <button
            class="no-button-style"
            v-on:click="
              showPurpose = false;
              emitDropdownSignal(null);
            "
          >
            <img src="/assets/img/icon-close.svg" />
          </button>
        </div>
        <form @submit.prevent="emitTradedSignal()" class="flex-column">
          <select v-model="optionVal" class="dropdown-field margin-top-1-05">
            <option value="">--- Select ---</option>
            <option value="0">Wholesale</option>
            <option value="1">Retail</option>
            <option value="2">Undecided</option>
            <option value="3">Budget</option>
          </select>
          <div class="flex-row">
            <primary-button title="Apply" class="margin-top margin-left-auto min-width-100" type="submit" />
          </div>
        </form>
      </div>
    </td>
  </tr>
</template>

<script>
import { getTopValue } from "../../../../helpers/v2/offerHelpers";
import { mapState, mapGetters } from "vuex";
import PrimaryButton from "../../../Buttons/v2/PrimaryButton.vue";

export default {
  name: "OfferTable",
  components: { PrimaryButton },
  props: {
    offer: {
      type: Object
    },
    winningOffer: {
      type: Object,
      default: null
    },
    small: {
      type: Boolean,
      default: false
    },
    allowArchive: {
      type: Boolean,
      default: false
    },
    showDropdown: {
      type: Boolean,
      default: false
    }
  },
  data: function () {
    return {
      optionVal: "",
      showPurpose: false
    };
  },
  methods: {
    getTopValue: function (offer) {
      return getTopValue(offer, this.currentDealer);
    },
    getSecondaryValue: function (offer) {
      if (offer.adjusted_value) {
        return offer.price;
      }

      return null;
    },
    getDealer: function (dealer) {
      if (dealer) {
        return this.dealerWithURL(dealer);
      }
      return null;
    },
    emitSignal: function (signalName) {
      this.$emit("offerOptionClicked", { signalName: signalName, offer: this.offer });
    },
    emitTradedSignal: function () {
      this.showPurpose = false;
      this.$emit("onTradedWithPurpose", { purpose: this.optionVal, offer: this.offer });
      this.$emit("onDropdownClicked", null);
    },
    emitCommentsSignal: function () {
      this.$emit("onShowComments", { offer: this.offer });
    },
    emitDropdownSignal: function (id) {
      this.$emit("onDropdownClicked", id);
    }
  },
  computed: {
    ...mapState({
      currentDealer: state => state.dealershipStore.currentDealer,
      currentReseller: state => state.resellerStore.currentReseller
    }),
    ...mapGetters({
      dealerWithURL: "dealershipStore/dealerWithURL"
    })
  }
};
</script>

<style lang="scss" scoped>
@import "@/scss/v2/_variables.scss";
@import "@/scss/v2/base.scss";

.traded-purpose-dropdown {
  position: absolute;
  background-color: white;
  z-index: 500;
  left: -200px;
}
</style>
