<template>
  <div>
    <div v-if="form.damage_maps && form.damage_maps.length > 0">
      <div v-if="showLegend" class="damage-legend body-2 margin-top">
        <div v-for="(item, i) in damageTypes" v-bind:key="i" class="damage-legend-item">
          <div class="damage-legend-indicator" :style="{ borderColor: item.colour }"></div>
          {{ item.name }}
        </div>
      </div>
      <div class="damage-map">
        <div v-if="form.damage_maps" class="damage-map-overlay">
          <viewer class="damage-map-overlay">
            <img
              class="damage-indicator body-2"
              v-for="n in 60"
              v-bind:key="n"
              :style="{ borderColor: getDamgeDataForIndex(n - 1).colour }"
              :src="getDamgeDataForIndex(n - 1).photo"
              :title="getDamgeDataForIndex(n - 1).name"
            />
          </viewer>
        </div>
        <img src="/assets/img/icon-damage-map.png" class="damage-map-img" />
      </div>
    </div>
  </div>
</template>

<script>
import { damageTypes } from "../../../../helpers/v2/formHelpers.js";

export default {
  name: "DamageMap",
  props: {
    form: {
      type: Object
    },
    showLegend: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    getDamgeDataForIndex: function (i) {
      if (!this.form.damage_maps) {
        return;
      }
      const coordX = i % 10;
      const coordY = Math.abs(Math.floor(i / 10) - 5);
      const damageMapObject = this.form.damage_maps.filter(dmo => {
        return dmo.area_x == coordY && dmo.area_y == coordX;
      });
      if (damageMapObject.length > 0) {
        return {
          colour: damageTypes[damageMapObject[0].code].colour,
          name: damageTypes[damageMapObject[0].code].name,
          photo: damageMapObject[0].photo
        };
      }
      return {
        colour: "transparent",
        photo: null
      };
    }
  },
  computed: {
    damageTypes: function () {
      return damageTypes;
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/scss/v2/_variables.scss";
@import "@/scss/v2/base.scss";

.damage-map {
  margin-top: 24px;
  position: relative;

  .damage-map-overlay {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-template-rows: repeat(6, 1fr);
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    position: absolute;

    .damage-indicator {
      border: 2px solid;
      border-radius: 4px;
    }
  }

  img {
    width: 100%;
  }
}

.damage-legend {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  width: 50%;

  .damage-legend-item {
    text-align: center;
    margin-left: 8px;
    margin-right: 8px;
    display: flex;
    flex-direction: column;

    .damage-legend-indicator {
      height: 32px;
      width: 32px;
      border: 2px solid;
      margin-left: auto;
      margin-right: auto;
      margin-top: 10px;
      margin-bottom: 2px;
      border-radius: 4px;
    }
  }
}
</style>
